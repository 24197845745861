import React from 'react'
import PropTypes from 'prop-types'

const SortDownIcon = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
    <g transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
      <path d="M10 545 c0 -13 24 -15 160 -15 136 0 160 2 160 15 0 13 -24 15 -160 15 -136 0 -160 -2 -160 -15z" />
      <path d="M506 544 c-3 -9 -6 -104 -6 -212 l0 -196 -38 37 c-21 20 -41 37 -45 37 -20 0 -1 -31 48 -80 l55 -54 55 54 c49 49 68 80 48 80 -4 0 -24 -17 -45 -37 l-38 -37 0 196 c0 190 -3 228 -20 228 -4 0 -11 -7 -14 -16z" />
      <path d="M10 395 c0 -13 24 -15 160 -15 136 0 160 2 160 15 0 13 -24 15 -160 15 -136 0 -160 -2 -160 -15z" />
      <path d="M10 245 c0 -13 24 -15 160 -15 136 0 160 2 160 15 0 13 -24 15 -160 15 -136 0 -160 -2 -160 -15z" />
      <path d="M10 95 c0 -13 24 -15 160 -15 136 0 160 2 160 15 0 13 -24 15 -160 15 -136 0 -160 -2 -160 -15z" />
    </g>
  </svg>
)

SortDownIcon.propTypes = {
  className: PropTypes.string,
}

SortDownIcon.defaultProps = {
  className: null
}

export default SortDownIcon
