import React, { useState } from 'react'
import FilterIcon from 'common/icons/FilterIcon'
import TransactionsFilterModal from './components/TransactionsFilterModal'

const TransactionsFilter = ({ className, transactionsFilter, setTransactionFilter }) => {
  const [modalIsOpen, toggleModal] = useState(false)

  return (
    <>
      <div tabIndex={0} role='button' onKeyDown={() => toggleModal(true)} onClick={() => toggleModal(true)} className={className}>
        <FilterIcon/>
      </div>
      <TransactionsFilterModal modalIsOpen={modalIsOpen} transactionsFilter={transactionsFilter} setTransactionFilter={setTransactionFilter} toggleModal={toggleModal} />
    </>
  )
}

export default TransactionsFilter