import moment from 'moment'
import { enumKind, enumType, toLocaleDateStringOptions } from 'common/constants'

// eslint-disable-next-line import/prefer-default-export
export const toLocaleDateString = (str, format='YYYY-MM-DD') => moment(str, format).toDate().toLocaleDateString('ru-ru', toLocaleDateStringOptions)

// eslint-disable-next-line 
export const generateRandomHex = () => '#'+(Math.random() * 0xFFFFFF << 0).toString(16).padStart(6, '0')

export const getTypeStr = (emunTypeValue, enumKindValue) => {
  let str = (emunTypeValue === enumType.INCOME) ? '\u002B' : '\u2212'
  if (enumKindValue === enumKind.MONTHLY) str += ' M'
  if (enumKindValue === enumKind.CORRECTION) str += ' K'
  return str
}