import React from 'react'
import Modal from 'common/components/Modal'
import DeleteCategory from './components/DeleteCategory'

const ModalDeleteCategory = ({ modalIsOpen, toggleModal, category }) => (
  <Modal
    onRequestClose={() => toggleModal(false)}
    isOpen={modalIsOpen}>
    <DeleteCategory category={category} toggleModal={toggleModal} />
  </Modal >
)

export default ModalDeleteCategory