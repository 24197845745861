import React from 'react'
import Block from 'components/Block'
import Budjet from './components/Budjet'
import PeriodStatistic from './components/PeriodStatistic'
import Transactions from './components/Transactions'
import style from './index.module.sass'

const Overview = ({transactionsFilter, setTransactionFilter}) => (
  <div className={style.overview}>
    <Block>
      <Budjet />
    </Block>
    <Block>
      <PeriodStatistic />
    </Block>
    <Block>
      <Transactions transactionsFilter={transactionsFilter} setTransactionFilter={setTransactionFilter} />
    </Block>
  </div>
)

export default Overview