import React from 'react'
import Select from 'react-select'
import PropTypes from 'prop-types'
import { enumKindOptions } from 'common/constants'

const EnumKind = ({ handleChange, form, fieldId }) => (
  <div>
    <label htmlFor={fieldId}>Вид транзакции</label>
    <Select
      placeholder="Вид транзакции"
      inputId={fieldId}
      isSearchable={false}
      defaultValue={form[fieldId]}
      onChange={handleChange(fieldId)}
      options={enumKindOptions}
      styles={{
        option: (styles) => ({
          ...styles,
          backgroundColor: null,
          color: 'black !important',
          '&:hover': {
            backgroundColor: '#6FA3D3',
          },
        }),
      }}
    />
  </div>
)

EnumKind.propTypes = {
  handleChange: PropTypes.func.isRequired,
  fieldId: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  form: PropTypes.object.isRequired
}

export default EnumKind
