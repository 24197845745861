/* eslint-disable react/prop-types */
import React, { forwardRef } from 'react'
import { toLocaleDateString } from 'common/helpers'
import style from './index.module.sass'

const CustomInput = forwardRef(({ startDate, endDate, onClick, onFocus, onChange, placeHolder, id }, ref) => {

  const getValue = () => {
    if (!startDate && !endDate) return placeHolder
    return `${(startDate || '') && toLocaleDateString(startDate)} - ${(endDate || '') && toLocaleDateString(endDate)}`
  }

  return (
    <input
      ref={ref}
      onFocus={onFocus}
      className={style.input}
      onChange={onChange}
      value={getValue()}
      id={id}
      onClick={onClick}
    />
  )
}
)

export default CustomInput