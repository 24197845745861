import React from 'react'
import PropTypes from 'prop-types'
import DatePicker from 'common/components/DatePicker'

const Date = ({ handleChange, form, fieldId }) => (
  <div>
    <label htmlFor={fieldId}>Дата транзакции</label>
    <DatePicker
      value={form[fieldId]}
      setValueFn={handleChange(fieldId)}
    />
  </div>
)

Date.propTypes = {
  handleChange: PropTypes.func.isRequired,
  fieldId: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  form: PropTypes.object.isRequired
}

export default Date
