/* eslint-disable react/prop-types */
import React from 'react'
import { useDispatch } from 'react-redux'
import Loader from 'components/Loader'
import PopOverHint from 'common/components/PopOverHint'
import { useGetBalanceQuery } from 'redux/tinkoffInvestmentSlice'
import { editSelfUser } from 'redux/authSlice'
import style from './index.module.sass'
import AddNewToken from './components/AddNewToken'

const TinkoffInvest = ({ user }) => {

  const dispatch = useDispatch()

  const {
    data,
    error,
    isFetching,
  } = useGetBalanceQuery({}, { skip: !user?.tinkoffInvestToken })

  const deleteTokenOnClick = () => {
    dispatch(editSelfUser({ user: { tinkoff_invest_token: '' }, tags: ['TinkoffInvestment'] }))
  }

  const render = () => {
    if (!user?.tinkoffInvestToken) return <AddNewToken />
    if (isFetching) return <Loader />
    if (error) return <AddNewToken error={error} />
    const { tinkoffInvestBalance, transactionsBalance } = data
    return (
      <div className={style.balance}>
        <div>Баланс ваших брокерских счетов: {tinkoffInvestBalance}р </div>
        <div>Общий баланс: {tinkoffInvestBalance + transactionsBalance}р </div>
        <button className={style.button} onClick={deleteTokenOnClick} type='button'>Отвязать токен</button>
      </div>
    )
  }

  return (
    <div className={style.tinkoffInvest}>
      <div className={style.header}>
        <div className={style.title}>Тинькофф инвестиции:</div>
        {(user?.showHints) ? (
          <PopOverHint>
            <div>
              Данная интеграция поможет видеть вам сумму вашего баланса по всем брокерским счетам + вашего баланса по всем транзакциям.<br />
              Если вы активно пользуетесь инвестициями, то это поможет оценить все ваши денежные активы в моменте.<br />
              Чтобы получить токен для интеграции перейдите в настройки личного кабинета инвестиций.<br />
              <b>Важно!</b> Отправляйте нам только Readonly token. После отправки вы уже никак не сможете его посмотреть, даже по API мы его вам не возвращаем.
            </div>
          </PopOverHint>
        ) : null}
      </div>
      {render()}
    </div>
  )
}

export default TinkoffInvest
