/* eslint-disable react/prop-types */
import React, { forwardRef } from 'react'
import { toLocaleDateStringOptions } from 'common/constants'
import style from './index.module.sass'

const CustomInput = forwardRef(({ date, onClick, onFocus, onChange, placeholder, id, customToLocaleDateStringOptions }, ref) => (
  <input
    ref={ref}
    className={style.input}
    onFocus={onFocus}
    onChange={onChange}
    placeholder={placeholder}
    value={date.toLocaleDateString('ru-ru', customToLocaleDateStringOptions || toLocaleDateStringOptions )}
    id={id}
    onClick={onClick}
  />
))

export default CustomInput