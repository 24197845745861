import React from 'react'
import { editSelfUser, logoutThunk } from 'redux/authSlice'
import { useDispatch, useSelector } from 'react-redux'
import Loader from 'components/Loader'
import style from './index.module.sass'

const AccountInfo = () => {
  const {
    user,
  } = useSelector(state => ({
    user: state.auth.user,
  }))

  const dispatch = useDispatch()

  const logoutOnClick = () => dispatch(logoutThunk())

  const render = () => {
    if (!user) return <Loader />
    return (
      <div className={style.content}>
        <div className={style.info}>
          <div>Id в нашей системе: <b>{user.id}</b></div>
          <div>Telegram Id: <b>{user.telegramId}</b></div>
          <div>Username: <b>{(user.username) ? user.username : 'отсутствует'}</b></div>
          <div>Имя пользователя: <b>{user.firstName}</b></div>
          <div>Аватарка: <a href={user.photoUrl}>{(user.photoUrl) ? 'фото' : 'отсутствует'}</a></div>
          <div>Подсказки:
            <span
              role='button'
              className={style.showHintsButton}
              onClick={
                () => dispatch(editSelfUser({ user: { showHints: !user.showHints } }))
              }
              onKeyDown={
                () => dispatch(editSelfUser({ user: { showHints: !user.showHints } }))
              }
              tabIndex={0}
            >
              {(user.showHints) ? 'показывать' : 'скрывать'}
            </span>
          </div>
        </div>
        <hr />
        <div>Username, имя пользователя, аватарка автоматически обновляются в соотвествии
          с данными из вашего тг аккаунта. Измените их в телеграмме и при следующей авторизации
          на сайте они обновятся в нашей системе. Также они обновляются при взаимодействии с тг ботом.</div>
        <button className={style.button} onClick={logoutOnClick} type='button'>Выйти</button>
      </div>
    )
  }

  return (
    <div className={style.accountInfo}>
      <div className={style.header}>
        <div className={style.title}>
          Информация об аккаунте
        </div>
      </div>
      {render()}
    </div>
  )
}

export default AccountInfo
