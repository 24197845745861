import apiSlice from './apiSlice'

export const transactionsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTransactions: builder.query({
      query: (params) => ({
        url: 'accountant/transactions/',
        params
      }),
      providesTags: (result = {}) => [
        'Transaction',
        ...result.results.map(({ id }) => ({ type: 'Transaction', id })),
      ],
    }),
    getTransaction: builder.query({
      query: (transactionId) => ({
        url:  `accountant/transactions/${transactionId}`,
      }),
      providesTags: (result, error, arg) => [{ type: 'Transaction', id: arg.id }],
    }),
    addNewTransaction: builder.mutation({
      query: (formData) => ({
        url: 'accountant/transactions/',
        method: 'POST',
        data: formData,
      }),
      invalidatesTags: ['Transaction', 'TransactionStats', 'CategoryStats'],
    }),
    deleteTransaction: builder.mutation({
      query: (transactionId) => ({
        url: `accountant/transactions/${transactionId}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Transaction', 'TransactionStats', 'CategoryStats'],
    }),
    editTransaction: builder.mutation({
      query: ({transactionId, formData}) => ({
        url: `accountant/transactions/${transactionId}/`,
        method: 'PUT',
        data: formData,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Transaction', id: arg.id }, 'TransactionStats', 'CategoryStats'],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetTransactionsQuery,
  useGetTransactionQuery,
  useAddNewTransactionMutation,
  useEditTransactionMutation,
  useDeleteTransactionMutation,
} = transactionsSlice