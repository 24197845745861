import React from 'react'
import PropTypes from 'prop-types'
import style from './index.module.sass'

const Title = ({ handleChange, form, fieldId }) => (
  <input
    className={style.title}
    placeholder='Название'
    id={fieldId}
    type="text"
    value={form[fieldId]}
    onChange={handleChange(fieldId)}
  />
)

Title.propTypes = {
  handleChange: PropTypes.func.isRequired,
  fieldId: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  form: PropTypes.object.isRequired
}

export default Title
