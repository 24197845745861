import React from 'react'
import { useDeleteTransactionMutation } from 'redux/transactionsSlice'
import { enumType as enumTypeConst } from 'common/constants'
import classNames from 'classnames'
import { toLocaleDateString, getTypeStr } from 'common/helpers'
import style from './index.module.sass'

const DeleteTransaction = ({ toggleModal, transaction }) => {
  const [deleteTransaction] = useDeleteTransactionMutation()

  return (
    <div className={style.deleteTransaction}>
      <div className={style.title}>Вы действительно хотите удалить данную транзакцию?</div>
      <div className={style.transaction}>
        <div className={style.field}>
          <span>Сумма: </span>
          {transaction?.amount}
        </div>
        <div className={
          classNames(
            style.field,
            {
              [style.income]: transaction.enumType === enumTypeConst.INCOME,
              [style.outcome]: transaction.enumType === enumTypeConst.OUTCOME,
            }
          )
        }>
          <span>Тип: </span>
          {getTypeStr(transaction?.enumType, transaction?.enumKind)}
        </div>
        <div className={style.field}>
          <span>Категория: </span>
          {(transaction?.category) ? transaction.category.title : '-'}
        </div>
        <div className={style.field}>
          <span>Дата: </span>
          {(transaction?.date) ? toLocaleDateString(transaction?.date) : '-'}
        </div>
        <div className={style.field}>
          <span>Название: </span>
          {(transaction?.title) ? transaction?.title : '-'}
        </div>
      </div>
      <div className={style.buttons}>
        <button onClick={() => toggleModal(false)} className={style.decline} type="button">
          Нет
        </button>
        <button onClick={() => {
          deleteTransaction(transaction.id)
          toggleModal(false)
        }} className={style.accept} type="button">
          Да
        </button>
      </div>
    </div>
  )
}

export default DeleteTransaction
