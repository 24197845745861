import Error from 'components/Error'
import Loader from 'components/Loader'
import moment from 'moment'
import React, { useState, useEffect, useCallback } from 'react'
import { enumTypeOptions } from 'common/constants'
import DateRangePicker from 'common/components/DateRangePicker'
import { useGetPieChartQuery } from 'redux/categoriesStatsSlice'
import Chart from './components/Chart'
import EnumTypeSelect from './components/EnumTypeSelect'
import style from './index.module.sass'

const PieChart = () => {

  const [dates, setDates] = useState(
    {
      startDate: moment().startOf('month').toDate(),
      endDate: moment().toDate(),
    }
  )

  const [enumType, setEnumType] = useState(enumTypeOptions[0])
  const [isLoading, setIsLoading] = useState(true)

  const {
    data: categories,
    error,
    isFetching,
  } = useGetPieChartQuery({
    enum_type: enumType.value,
    transaction_start_date: moment(dates.startDate).format('YYYY-MM-DD'),
    transaction_end_date: moment(dates.endDate).format('YYYY-MM-DD'),
  })

  useEffect(() => {
    if (!isFetching) {
      setIsLoading(false)
    }
  }, [isFetching])

  const render = useCallback(() => {
    if (isLoading) return <Loader />
    if (error) return <Error error={error} />
    return <Chart dates={dates} categories={categories} />
  }, [isLoading, error, categories, dates])

  return (
    <div className={style.pieChart}>
      <div className={style.header}>
        <div className={style.title}>
          Круговая диаграмма
        </div>
        <div className={style.filters}>
          <div>
            <EnumTypeSelect enumType={enumType} setEnumType={setEnumType} enumTypeOptions={enumTypeOptions} />
          </div>
          <div>
            <DateRangePicker
              placeHolder='Выберите даты'
              dates={dates}
              setDates={setDates}
            />
          </div>
        </div>
      </div>
      {render()}
    </div>
  )
}

export default PieChart