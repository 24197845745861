import React from 'react'
import PropTypes from 'prop-types'

const Amount = ({ handleChange, form, fieldId }) => (
  <div>
    <label htmlFor={fieldId}>Сумма</label>
    <input
      id={fieldId}
      placeholder="Сумма"
      type="number"
      value={form[fieldId]}
      onChange={handleChange(fieldId)}
    />
  </div>
)

Amount.propTypes = {
  handleChange: PropTypes.func.isRequired,
  fieldId: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  form: PropTypes.object.isRequired
}

export default Amount
