import React from 'react'
import Block from 'components/Block'
import AccountInfo from './components/AccountInfo'
import Integrations from './components/Integrations'

const Account = () => (
  <div>
    <Block>
      <AccountInfo />
    </Block>
    <Block>
      <Integrations />
    </Block>
  </div>
)

export default Account
