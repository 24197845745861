import React from 'react'
import { useDeleteCategoryMutation } from 'redux/categoriesSlice'
import { enumType as enumTypeConst } from 'common/constants'
import classNames from 'classnames'
import { toLocaleDateString, getTypeStr } from 'common/helpers'
import style from './index.module.sass'

const DeleteCategory = ({ toggleModal, category }) => {
  const [deleteCategory] = useDeleteCategoryMutation()

  return (
    <div className={style.deleteCategory}>
      <div className={style.title}>Вы действительно хотите удалить данную категорию? Вместе с ней удалятся связанные транзакции!</div>
      <div className={style.category}>
        <div className={
          classNames(
            style.field,
            {
              [style.income]: category.enumType === enumTypeConst.INCOME,
              [style.outcome]: category.enumType === enumTypeConst.OUTCOME,
            }
          )
        }>
          <span>Тип: </span>
          {getTypeStr(category?.enumType, category?.enumKind)}
        </div>
        <div className={style.field}>
          <span>Название: </span>
          {category.title}
        </div>
        <div className={classNames(style.field, style.color)}>
          <span>Цвет: </span>
          <div style={{backgroundColor: category.color}} className={style.colorBlock}/>
        </div>
        <div className={style.field}>
          <span>Дата создания: </span>
          {(category?.created) ? toLocaleDateString(category?.created) : '-'}
        </div>
      </div>
      <div className={style.buttons}>
        <button onClick={() => toggleModal(false)} className={style.decline} type="button">
          Нет
        </button>
        <button onClick={() => {
          deleteCategory(category.id)
          toggleModal(false)
        }} className={style.accept} type="button">
          Да
        </button>
      </div>
    </div>
  )
}

export default DeleteCategory
