import React from 'react'
import classNames from 'classnames'
import SortDownIcon from 'common/icons/sorting/SortDownIcon'
import SortIcon from 'common/icons/sorting/SortIcon'
import SortUpIcon from 'common/icons/sorting/SortUpIcon'
import style from './index.module.sass'

const TableHead = ({ hideIndexCol, setTransactionFilter, transactionsFilter, hideCreatorCol }) => {
  const setOrderingFilter = (field) => {
    if (transactionsFilter.ordering === null || (transactionsFilter.ordering && !transactionsFilter.ordering.includes(field))) return setTransactionFilter({ ...transactionsFilter, ordering: `-${field}` })
    if (transactionsFilter.ordering && (transactionsFilter.ordering[0] === '-')) return setTransactionFilter({ ...transactionsFilter, ordering: `${field}` })
    return setTransactionFilter({ ...transactionsFilter, ordering: null })
  }

  const getActiveOrderingIcon = (field) => {
    if (transactionsFilter.ordering === field) return <SortUpIcon />
    if (transactionsFilter.ordering === `-${field}`) return <SortDownIcon />
    return <SortIcon />
  }

  return (
    <div className={classNames(style.flexTable, style.header, {
      [style.hideIndexCol]: hideIndexCol && !hideCreatorCol,
      [style.hideCreatorCol]: hideCreatorCol && !hideIndexCol,
      [style.hideIndexAndCreatorCol]: hideCreatorCol && hideIndexCol
    })} role="rowgroup">
      <div
        className={classNames(style.flexRow, style.indexCol)}
        role="columnheader"
      >
        №
      </div>
      <div
        onClick={() => setOrderingFilter('amount')}
        onKeyDown={() => setOrderingFilter('amount')}
        className={classNames(style.flexRow, style.amountCol, style.ordering)}
        role="columnheader"
        tabIndex={0}
      >
        Сумма, р.
        {getActiveOrderingIcon('amount')}
      </div>
      <div className={classNames(style.flexRow, style.typeCol)} role="columnheader">Тип</div>
      <div className={classNames(style.flexRow, style.categoryCol)} role="columnheader">Категория</div>
      <div className={classNames(style.flexRow, style.creatorCol)} role="columnheader">Создатель</div>
      <div
        onClick={() => setOrderingFilter('date')}
        onKeyDown={() => setOrderingFilter('date')}
        className={classNames(style.flexRow, style.dateCol, style.ordering)}
        role="columnheader"
        tabIndex={0}
      >
        Дата
        {getActiveOrderingIcon('date')}
      </div>
      <div className={classNames(style.flexRow, style.titleCol)} role="columnheader">Название</div>
      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
      <div className={classNames(style.flexRow, style.buttonsCol)} role="columnheader" />
    </div>
  )
}

export default TableHead
