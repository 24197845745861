import React from 'react'
import Select from 'react-select'
import { enumTypeOptions } from 'common/constants'

const EnumTypeMulti = ({setForm, form, fieldId, categoryFieldId}) => (
  <div>
    <Select
      isMulti
      placeholder="Типы транзакций"
      inputId={fieldId}
      isSearchable={false}
      defaultValue={form[fieldId]}
      onChange={(value) => {
        setForm({
          ...form,
          [fieldId]: value,
          [categoryFieldId]: [],
        })
      }}
      options={enumTypeOptions}
      styles={{
        option: (styles) => ({
          ...styles,
          backgroundColor: null,
          color: 'black !important',
          '&:hover': {
            backgroundColor: '#6FA3D3',
          },
        }),
      }}
    />
  </div>
)

export default EnumTypeMulti
