import React from 'react'
import classNames from 'classnames'
import SortDownIcon from 'common/icons/sorting/SortDownIcon'
import SortIcon from 'common/icons/sorting/SortIcon'
import SortUpIcon from 'common/icons/sorting/SortUpIcon'
import style from './index.module.sass'

const TableHead = ({categoryFilter, setCategoryFilter}) => {

  const setOrderingFilter = (field) => {
    if (categoryFilter.ordering === null || (categoryFilter.ordering && !categoryFilter.ordering.includes(field))) return setCategoryFilter({ ...categoryFilter, ordering: `-${field}` })
    if (categoryFilter.ordering && (categoryFilter.ordering[0] === '-') ) return setCategoryFilter({ ...categoryFilter, ordering: `${field}` })
    return setCategoryFilter({ ...categoryFilter, ordering: null })
  }

  const getActiveOrderingIcon = (field) => {
    if (categoryFilter.ordering === field) return <SortUpIcon/>
    if (categoryFilter.ordering === `-${field}`) return <SortDownIcon/>
    return <SortIcon />
  }

  return (
    <div className={classNames(style.flexTable, style.header)} role="rowgroup">
      <div
        className={classNames(style.flexRow, style.indexCol)}
        role="columnheader"
      >
        №
      </div>
      <div
        onClick={() => setOrderingFilter('enum_type')}
        onKeyDown={() => setOrderingFilter('enum_type')}
        className={classNames(style.flexRow, style.enumTypeCol, style.ordering)}
        role="columnheader"
        tabIndex={0}
      >
        Тип
        {getActiveOrderingIcon('enum_type')}
      </div>
      <div className={classNames(style.flexRow, style.titleCol)} role="columnheader">Название</div>
      <div className={classNames(style.flexRow, style.colorCol)} role="columnheader">Цвет</div>
      <div
        className={classNames(style.flexRow, style.createdCol)}
        role="columnheader"
        tabIndex={0}
      >
        Дата создания
      </div>
      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
      <div className={classNames(style.flexRow, style.buttonsCol)} role="columnheader" />
    </div>
  )
}
export default TableHead
