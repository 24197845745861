/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { useSelector } from 'react-redux'
import Loader from 'components/Loader'
import style from './index.module.sass'
import Component from './components/Component'

const Budjet = () => {

  const {
    user,
  } = useSelector(state => ({
    user: state.auth.user,
  }))

  return (
    <div className={style.budjet}>
      {(!user) ? <Loader/> : <Component user={user}/>}
    </div>
  )
}

export default Budjet