import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { useGetTransactionsQuery } from 'redux/transactionsSlice'
import Loader from 'components/Loader'
import Error from 'components/Error'
import ModalTransaction from 'common/components/ModalTransaction'
import ModalDeleteTransaction from 'common/components/ModalDeleteTransaction'
import TableHead from './TableHead'
import TableTransaction from './TableTransaction'

import style from './index.module.sass'

const TransactionsTable = ({ setTransactionFilter, defaultLimit, transactionsFilter, setTransactionsCount, hideIndexCol }) => {

  const {
    user,
  } = useSelector(state => ({
    user: state.auth.user,
  }))

  const hideCreatorCol = !user?.activeCustomGroup

  const [limit, setLimit] = useState(defaultLimit || 5)

  const [modalTransactionIsOpen, toggleModalTransaction] = useState({ isOpened: false, transaction: null })
  const [modalTransactionDeleteIsOpen, toggleModalTransactionDelete] = useState({ isOpened: false, transaction: null })

  const {
    data: transactions,
    error,
    isFetching,
  } = useGetTransactionsQuery(
    {
      limit,
      enum_type__in: transactionsFilter?.enumTypes.map((enumType) => enumType.value).join(','),
      enum_kind__in: transactionsFilter?.enumKinds.map((enumKind) => enumKind.value).join(','),
      category__id__in: transactionsFilter?.categories.map((category) => category.value).join(','),
      amount__lte: transactionsFilter?.amountLte || null,
      amount__gte: transactionsFilter?.amountGte || null,
      date__lte: transactionsFilter?.dateLte && moment(transactionsFilter?.dateLte).format('YYYY-MM-DD'),
      date__gte: transactionsFilter?.dateGte && moment(transactionsFilter?.dateGte).format('YYYY-MM-DD'),
      title__in: transactionsFilter?.titleIn,
      ordering: `${(transactionsFilter?.ordering) ? `${transactionsFilter?.ordering},` : ''}-created`,
    }

  )

  useEffect(() => {
    if (setTransactionsCount) {
      setTransactionsCount(transactions?.count || 0)
    }
  }, [transactions, setTransactionsCount])

  const handleScroll = (e) => {
    const bottom = e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight * 0.9
    if (bottom && !isFetching && transactions?.count > limit) { setLimit(limit + 30) }
  }

  const renderItems = () => {
    if (isFetching && !transactions?.results?.length) return <Loader />
    if (error) return <Error error={error} />
    return transactions.results.map((transaction, index) => (
      <TableTransaction
        hideIndexCol={hideIndexCol}
        hideCreatorCol={hideCreatorCol}
        index={index}
        key={transaction.id}
        id={transaction.id}
        amount={transaction.amount}
        enumType={transaction.enumType}
        enumKind={transaction.enumKind}
        category={transaction.category}
        creator={transaction.creator}
        date={transaction.date}
        title={transaction.title}
        toggleModalTransaction={toggleModalTransaction}
        toggleModalTransactionDelete={toggleModalTransactionDelete}
      />
    )
    )
  }

  return (
    <div className={style.table}>
      <div className={style.tableContainer} role="table">
        <TableHead setTransactionFilter={setTransactionFilter} transactionsFilter={transactionsFilter} hideIndexCol={hideIndexCol} hideCreatorCol={hideCreatorCol} />
        <div onScroll={handleScroll} className={style.transactionsList}>
          {renderItems()}
        </div>
        <ModalTransaction modalIsOpen={modalTransactionIsOpen.isOpened} toggleModal={(isOpened) => toggleModalTransaction({ isOpened, transaction: modalTransactionIsOpen.transaction })} transaction={modalTransactionIsOpen.transaction} />
        <ModalDeleteTransaction modalIsOpen={modalTransactionDeleteIsOpen.isOpened} toggleModal={(isOpened) => toggleModalTransactionDelete({ isOpened, transaction: modalTransactionDeleteIsOpen.transaction })} transaction={modalTransactionDeleteIsOpen.transaction} />
      </div>
    </div>
  )
}

export default TransactionsTable
