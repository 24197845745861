import React, { useState } from 'react'
import Block from 'components/Block'
import CategoriesTable from 'common/components/CategoriesTable'
import ModalCategory from 'common/components/ModalCategory'
import { defaultCategoryFilter } from 'common/constants'
import style from './index.module.sass'

const Categories = () => {
  const [categoriesCount, setCategoriesCount] = useState(0)
  const [categoryFilter, setCategoryFilter] = useState(defaultCategoryFilter)
  const [modalIsOpen, toggleModal] = useState(false)

  return (
    <Block className={style.categoriesBlock}>
      <div className={style.categories}>
        <div className={style.header}>
          <div className={style.title}>
            Все категории
          </div>
          <div className={style.rightHeader}>
            <div className={style.count}>Кол-во: <b>{categoriesCount}</b></div>
            <button onClick={() => {
              toggleModal(true)
            }} className={style.addCategory} type="button">+</button>
            <ModalCategory modalIsOpen={modalIsOpen} toggleModal={toggleModal}/>
          </div>
        </div>
        <div className={style.content}>
          <CategoriesTable categoryFilter={categoryFilter} setCategoryFilter={setCategoryFilter} setCategoriesCount={(count) => setCategoriesCount(count)} defaultLimit={20} />
        </div>
      </div>
    </Block>
  )
}

export default Categories
