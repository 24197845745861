import apiSlice from './apiSlice'

export const tinkoffInvestmentSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getBalance: builder.query({
      query: (params) => ({
        url: 'tinkoff-investment/get-balance/',
        params
      }),
      providesTags: () => [{ type: 'TinkoffInvestment', id: 'balance' }],
    }),
  }),
  overrideExisting: false,
})

export const { useGetBalanceQuery } = tinkoffInvestmentSlice