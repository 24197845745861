import React from 'react'
import PropTypes from 'prop-types'
import { HexColorPicker } from 'react-colorful'
import style from './index.module.sass'

const Color = ({ handleChange, form, fieldId }) => (
  <div className={style.color}>
    <div htmlFor={fieldId}>Цвет</div>
    <div className={style.colorPicker}>
      <HexColorPicker color={form[fieldId]} onChange={handleChange(fieldId)} />
    </div>
  </div>
)

Color.propTypes = {
  handleChange: PropTypes.func.isRequired,
  fieldId: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  form: PropTypes.object.isRequired
}

export default Color
