import React from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import { toLocaleDateString } from 'common/helpers'
import style from './index.module.sass'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

const Chart = ({ monthData }) => {

  const options = {
    responsive: true,
  }

  const data = {
    labels: monthData.map((month) => (month.isPrediction ? `Прогноз на ${toLocaleDateString(month.date)}` : toLocaleDateString(month.date))),
    datasets: [
      {
        label: 'Баланс',
        data: monthData.map((month) => month.balance),
      },
    ],
  }

  return (
    <div className={style.content}>
      <div className={style.chart}>
        <Line options={options} data={data} />
      </div>
    </div>
  )
}

export default Chart