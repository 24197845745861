import React, { useState } from 'react'
import { useAddNewCategoryMutation, useEditCategoryMutation } from 'redux/categoriesSlice'
import { isEqual } from 'lodash'
import { enumTypeOptions } from 'common/constants'
import { generateRandomHex } from 'common/helpers'
import style from './index.module.sass'
import EnumType from './components/EnumType'
import Title from './components/Title'
import Color from './components/Color'

const Form = ({ toggleModal, category }) => {
  const [addNewCategory] = useAddNewCategoryMutation()
  const [editCategory] = useEditCategoryMutation()

  const [form, setForm] = useState((category) ? {
    enumType: enumTypeOptions.find((option) => option.value === category.enumType),
    color: category.color,
    title: category.title || '',
  } : {
    enumType: enumTypeOptions[0],
    color: generateRandomHex(),
    title: ''
  })

  const handleChange = (fieldId) => (value) => {
    if (value?.target) {
      return setForm({
        ...form,
        [fieldId]: value.target.value,
      })
    }
    return setForm({
      ...form,
      [fieldId]: value,
    })
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    const formData = {
      enum_type: form.enumType.value,
      color: form.color,
      title: form.title || null,
    }
    if (category) {
      const categoryData = {
        enum_type: category.enumType,
        color: category.color,
        title: category.title || null,
      }
      const { id } = category
      if (!isEqual(categoryData, formData)) editCategory({ categoryId: id, formData })
    }
    else {
      addNewCategory(formData)
    }
    toggleModal(false)
  }

  return (
    <form onSubmit={handleSubmit} className={style.form}>
      {(!category) ? <EnumType handleChange={handleChange} form={form} setForm={setForm} fieldId="enumType" /> : null}
      <Title handleChange={handleChange} form={form} setForm={setForm} fieldId="title" />
      <Color handleChange={handleChange} form={form} setForm={setForm} fieldId="color" />
      <button className={style.button} type="submit">{(category) ? 'Изменить' : 'Добавить'}</button>
    </form>
  )
}

export default Form
