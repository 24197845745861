import React from 'react'
import moment from 'moment'
import style from './index.module.sass'
/* eslint-disable react/prop-types */

const CustomHeader = ({
  monthDate,
  decreaseMonth,
  increaseMonth,
  onChange,
  datepickerRef
}) => {
  const closeDatePicker = () => {
    datepickerRef.setOpen(false)
  }

  const currentDate = moment()

  return (
    <div className={style.container}>
      <div className={style.buttons}>
        <button
          type="button"
          onClick={() => {
            onChange([
              currentDate.clone().startOf('month').toDate(),
              currentDate.toDate(),
            ])
            closeDatePicker()
          }}
        >
          Текущий месяц
        </button>
        <button
          type="button"
          onClick={() => {
            onChange([
              currentDate.clone().startOf('month').subtract(2, 'months').toDate(),
              currentDate.toDate(),
            ])
            closeDatePicker()
          }}
        >
          Три месяца
        </button>
        <button
          type="button"
          onClick={() => {
            onChange([
              currentDate.clone().startOf('year').toDate(),
              currentDate.toDate(),
            ])
            closeDatePicker()
          }}
        >
          Текущий год
        </button>
      </div>
      <div className={style.header}>
        <button
          aria-label="Previous Month"
          className="react-datepicker__navigation react-datepicker__navigation--previous"
          onClick={decreaseMonth}
          type="button"
        >
          <span
            className="react-datepicker__navigation-icon react-datepicker__navigation-icon--previous"
          >
            {'<'}
          </span>
        </button>
        <span className="react-datepicker__current-month">
          {monthDate.toLocaleString('ru-RU', {
            month: 'long',
            year: 'numeric',
          })}
        </span>
        <button
          aria-label="Next Month"
          className="react-datepicker__navigation react-datepicker__navigation--next"
          onClick={increaseMonth}
          type="button"
        >
          <span
            className="react-datepicker__navigation-icon react-datepicker__navigation-icon--next"
          >
            {'>'}
          </span>
        </button>
      </div>
    </div>
  )
}

export default CustomHeader
