import React, { useState } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import DateRangePicker from 'common/components/DateRangePicker'
import { defaultTransactionFilter } from 'common/constants'
import style from './index.module.sass'
import EnumTypeMulti from './components/EnumTypeMulti'
import EnumKindMulti from './components/EnumKindMulti'
import CategoriesMulti from './components/CategoriesMulti'
import AmountMulti from './components/AmountMulti'
import Title from './components/Title'

const Form = ({ toggleModal, transactionsFilter, setTransactionFilter }) => {

  const [form, setForm] = useState({ ...transactionsFilter })

  const handleChange = (fieldId) => (value) => {
    if (value?.target) {
      return setForm({
        ...form,
        [fieldId]: value.target.value,
      })
    }
    return setForm({
      ...form,
      [fieldId]: value,
    })
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    setTransactionFilter(form)

    toggleModal(false)
  }

  return (
    <form onSubmit={handleSubmit} className={style.form}>
      <EnumKindMulti handleChange={handleChange} form={form} fieldId="enumKinds" />
      <EnumTypeMulti form={form} setForm={setForm} fieldId="enumTypes" categoryFieldId="categories" />
      <AmountMulti form={form} handleChange={handleChange} amountLteFieldId="amountLte" amountGteFieldId="amountGte" />
      <CategoriesMulti handleChange={handleChange} form={form} fieldId="categories" />
      <DateRangePicker
        placeHolder='Выберите даты'
        dates={{ startDate: form.dateGte, endDate: form.dateLte }}
        setDates={(value) => {
          setForm({
            ...form,
            dateLte: value.endDate,
            dateGte: value.startDate,
          })
        }}
      />
      <Title handleChange={handleChange} form={form} fieldId="titleIn" />
      <button className={classNames(style.button, style.set)} type="submit">Применить</button>
      <button className={style.button} type="button" onClick={() => {
        setTransactionFilter(defaultTransactionFilter)
        toggleModal(false)
      }}>Сбросить</button>
    </form>
  )
}

Form.propTypes = {
  toggleModal: PropTypes.func.isRequired,
}

export default Form
