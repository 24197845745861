/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { NavLink } from 'react-router-dom'
import TransactionsTable from 'common/components/TransactionsTable'
import TransactionsFilter from 'common/components/TransactionsFilter'
import style from './index.module.sass'

const Transactions = ({transactionsFilter, setTransactionFilter}) => (
  <div className={style.transactions}>
    <div className={style.header}>
      <div className={style.title}>
        Последние транзакции
      </div>
      <div className={style.rightHeader}>
        <TransactionsFilter className={style.transactionsFilter} transactionsFilter={transactionsFilter} setTransactionFilter={setTransactionFilter} />
      </div>
    </div>
    <div className={style.content}>
      <TransactionsTable hideIndexCol transactionsFilter={transactionsFilter} setTransactionFilter={setTransactionFilter} />
      <div className={style.allTransactions}>
        <NavLink to='/transactions'>
          Все транзакции
        </NavLink>
      </div>
    </div>
  </div>
)
export default Transactions