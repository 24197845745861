import React, { useCallback, useState } from 'react'
import { noop } from 'lodash'
import classNames from 'classnames'
import { Popover } from 'react-tiny-popover'

import useMedia, { DEVICES } from 'hooks/useMedia'
import Modal from 'common/components/Modal'

import style from './index.module.sass'

const PopOverHint = ({ children, signClassname }) => {
  const device = useMedia()

  const [isOpen, setOpen] = useState(false)
  const handleMouseOut = useCallback(() => (isOpen ? setOpen(false) : noop), [isOpen])
  const handleMouseOver = useCallback(() => (!isOpen ? setOpen(true) : noop), [isOpen])

  return device === DEVICES.DESKTOP ? (
    <Popover
      isOpen={isOpen}
      positions={['top', 'right', 'left', 'bottom']}
      padding={10}
      align='center'
      containerClassName={style.container}
      content={
        <div
          className={style.hintContainer}
        >
          {children}
          <div className={style.info}>
            <b>Скрыть / показать подсказки можно на странице &apos;Мой аккаунт&apos;</b>
          </div>
        </div>
      }
    >
      <div
        className={classNames(style.sign, {
          [signClassname]: signClassname,
        })}
        onBlur={handleMouseOut}
        onFocus={handleMouseOver}
        onMouseOut={handleMouseOut}
        onMouseOver={handleMouseOver}
      >
        ?
      </div>
    </Popover >
  ) : (
    <>
      <div
        className={classNames(style.sign, {
          [signClassname]: signClassname,
        })}
        onClick={() => setOpen(true)}
        onKeyDown={() => setOpen(true)}
        role="button"
        tabIndex={0}
      >
        ?
      </div>
      {isOpen ? (
        <Modal onRequestClose={() => setOpen(false)}
          isOpen={isOpen}>
          {children}
          <div className={style.info}>
            <b>Скрыть / показать подсказки можно на странице &apos;Мой аккаунт&apos;</b>
          </div>
        </Modal>
      ) : null}
    </>
  )
}

export default PopOverHint
