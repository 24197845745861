import React from 'react'
import PropTypes from 'prop-types'

const Title = ({ handleChange, form, fieldId }) => (
  <div>
    <label htmlFor={fieldId}>Название</label>
    <input
      id={fieldId}
      placeholder="Название"
      type="text"
      value={form[fieldId]}
      onChange={handleChange(fieldId)}
    />
  </div>
)

Title.propTypes = {
  handleChange: PropTypes.func.isRequired,
  fieldId: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  form: PropTypes.object.isRequired
}

export default Title
