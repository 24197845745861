import React, {useState} from 'react'
import { Route, Routes } from 'react-router-dom'
import { defaultTransactionFilter } from 'common/constants'
import style from './index.module.sass'
import Overview from './components/Overview'
import NotFound from './components/NotFound'
import Account from './components/Account'
import Charts from './components/Charts'
import Transactions from './components/Transactions'
import Categories from './components/Categories'

const Content = () => {
  const [transactionsFilter, setTransactionFilter] = useState(defaultTransactionFilter)

  return (
    <div className={style.content}>
      <Routes>
        <Route exact path='/' element={<Overview transactionsFilter={transactionsFilter} setTransactionFilter={setTransactionFilter} />} />
        <Route exact path='/charts' element={<Charts />} />
        <Route exact path='/account' element={<Account />} />
        <Route exact path='/transactions' element={<Transactions transactionsFilter={transactionsFilter} setTransactionFilter={setTransactionFilter} />} />
        <Route exact path='/categories' element={<Categories />} />
        <Route path='*' element={<NotFound />} />
      </Routes>
    </div>
  )
}
export default Content