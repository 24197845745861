import React, { useState, useEffect } from 'react'
import { useGetCategoriesQuery } from 'redux/categoriesSlice'
import Loader from 'components/Loader'
import Error from 'components/Error'
import ModalCategory from 'common/components/ModalCategory'
import ModalDeleteCategory from 'common/components/ModalDeleteCategory'
import TableHead from './TableHead'
import TableCategory from './TableCategory'

import style from './index.module.sass'

const CategoriesTable = ({ setCategoryFilter, defaultLimit, categoryFilter, setCategoriesCount }) => {

  const [limit, setLimit] = useState(defaultLimit || 5)

  const [modalCategoryIsOpen, toggleModalCategory] = useState({ isOpened: false, category: null })
  const [modalCategoryDeleteIsOpen, toggleModalCategoryDelete] = useState({ isOpened: false, category: null })

  const {
    data: categories,
    error,
    isFetching,
  } = useGetCategoriesQuery(
    {
      limit,
      ordering: `${(categoryFilter?.ordering)? `${categoryFilter?.ordering},` : ''}-created`,
    }

  )

  useEffect(() => {
    if (setCategoriesCount) {
      setCategoriesCount(categories?.count || 0)
    }
  }, [categories, setCategoriesCount])

  const handleScroll = (e) => {
    const bottom = e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight * 0.9
    if (bottom && !isFetching && categories?.count > limit) { setLimit(limit + 30) }
  }

  const renderItems = () => {
    if (isFetching && !categories?.results?.length) return <Loader />
    if (error) return <Error error={error} />
    return categories.results.map((category, index) => (
      <TableCategory
        index={index}
        key={category.id}
        id={category.id}
        enumType={category.enumType}
        color={category.color}
        title={category.title}
        created={category.created}
        toggleModalCategory={toggleModalCategory}
        toggleModalCategoryDelete={toggleModalCategoryDelete}
      />
    )
    )
  }

  return (
    <div className={style.table}>
      <div className={style.tableContainer} role="table">
        <TableHead setCategoryFilter={setCategoryFilter} categoryFilter={categoryFilter} />
        <div onScroll={handleScroll} className={style.categoriesList}>
          {renderItems()}
        </div>
        <ModalCategory modalIsOpen={modalCategoryIsOpen.isOpened} toggleModal={(isOpened) => toggleModalCategory({ isOpened, category: modalCategoryIsOpen.category })} category={modalCategoryIsOpen.category} />
        <ModalDeleteCategory modalIsOpen={modalCategoryDeleteIsOpen.isOpened} toggleModal={(isOpened) => toggleModalCategoryDelete({ isOpened, category: modalCategoryDeleteIsOpen.category })} category={modalCategoryDeleteIsOpen.category} />
      </div>
    </div>
  )
}

export default CategoriesTable
