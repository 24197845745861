import React from 'react'
import { useSelector } from 'react-redux'
import Loader from 'components/Loader'
import style from './index.module.sass'
import TinkoffInvest from './components/TinkoffInvest'

const Integrations = () => {
  const {
    user,
  } = useSelector(state => ({
    user: state.auth.user,
  }))

  const render = () => {
    if (!user) return <Loader />
    return (
      <div className={style.content}>
        <TinkoffInvest user={user}/>
      </div>
    )
  }

  return (
    <div className={style.integrations}>
      <div className={style.header}>
        <div className={style.title}>
          Интеграции
        </div>
      </div>
      {render()}
    </div>
  )
}

export default Integrations
