/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import { useGetBudjetQuery } from 'redux/transactionsStatsSlice'
import PopOverHint from 'common/components/PopOverHint'
import Loader from 'components/Loader'
import Error from 'components/Error'
import style from './index.module.sass'
import Content from './components/Content'
import Savings from './components/Savings'

const Component = ({ user }) => {
  const [savings, setSavings] = useState((user.activeCustomGroup) ? user.activeCustomGroup.savings : user.savings)

  const {
    data,
    error,
    isFetching,
  } = useGetBudjetQuery()

  const render = () => {
    if (isFetching) return <Loader />
    if (error) return <Error error={error} />
    return <Content user={user} {...data} />
  }

  return (
    <>
      <div className={style.header}>
        <div className={style.title}>
          <span>
            Бюджет
          </span>
          {(user?.showHints) ? (<PopOverHint>
            Помогает рассчитать сумму, которую вы можете потратить за текущий день, с учетом откладываемых денег, чтобы вписаться в бюджет.
          </PopOverHint>) : null}
        </div>
        <div className={style.rightHeader}>
          {(user?.showHints) ? (<PopOverHint>
            <div>
              Если следовать бюджету, то в конце месяца вы сможете сохранить
              <b>&nbsp;{data?.periodSavingsAmount}р.</b>, от дохода за этот месяц.<br />
              Формула для расчёта суммы откладываемых денег:<br />
              ежемесячный доход(<b>{data?.periodMonthlyIncome}р.</b>)
              * процент от ежемесячного дохода(<b>{savings / 100}</b>) = сумма,
              которая останется в конце месяца(<b>{data?.periodSavingsAmount}р.</b>)
            </div>
          </PopOverHint>) : null}
          <Savings savings={savings} setSavings={setSavings} />
        </div>
      </div>
      {render()}
    </>
  )
}

export default Component