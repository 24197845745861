import React from 'react'
import Select from 'react-select'
import PropTypes from 'prop-types'
import { useGetCategoriesQuery } from 'redux/categoriesSlice'
import useMedia, { DEVICES } from 'hooks/useMedia'
import Error from 'components/Error'
import {enumType} from 'common/constants'

const getCategoryLabel = (category) => {
  if (!category?.enumType) return ''
  return (category?.enumType === enumType.INCOME) ? '+' : '-'
}

const CategoriesMulti = ({ handleChange, form, fieldId }) => {
  const device = useMedia()

  const {
    data,
    error,
    isFetching,
  } = useGetCategoriesQuery(
    {
      enum_type__in: form?.enumTypes.map( (enumTypeVal) => enumTypeVal.value ).join(','),
      ordering: '-transactions_count',
    }
  )

  const categoriesData = (isFetching) ? [] : data.results.map((category) => ({
    label: `(${getCategoryLabel(category)})${category?.title}`,
    value: category?.id,
  }))

  return (
    <div>
      {(error) ? <Error error={error} /> : (
        <Select
          isMulti
          placeholder="Категории"
          value={form[fieldId]}
          isLoading={isFetching}
          inputId={fieldId}
          isSearchable={device !== DEVICES.MOBILE}
          onChange={handleChange(fieldId)}
          options={categoriesData}
          styles={{
            option: (styles) => ({
              ...styles,
              backgroundColor: null,
              color: 'black !important',
              '&:hover': {
                backgroundColor: '#6FA3D3',
              },
            }),
            menu: (styles) => ({
              ...styles,
              maxHeight: '200px'
            }),
            menuList: (styles) => ({
              ...styles,
              maxHeight: '200px'
            }),
          }}
        />
      )}
    </div>
  )
}

CategoriesMulti.propTypes = {
  handleChange: PropTypes.func.isRequired,
  fieldId: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  form: PropTypes.object.isRequired
}

export default CategoriesMulti
