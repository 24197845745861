import React, {useState} from 'react'
import ModalTransaction from 'common/components/ModalTransaction'
import { enumType } from 'common/constants'
import style from './index.module.sass'

const AddTransaction = () => {
  const [modalIsOpen, toggleModal] = useState(false)
  const [transactionType, setTransactionType] = useState(enumType.OUTCOME)

  return (
    <div className={style.addTransaction}>
      <button onClick={()=>{
        setTransactionType(enumType.OUTCOME)
        toggleModal(true)
      }} className={style.outcome} type="button">-</button>
      <button onClick={()=>{
        setTransactionType(enumType.INCOME)
        toggleModal(true)
      }} className={style.income} type="button">+</button>
      <ModalTransaction modalIsOpen={modalIsOpen} toggleModal={toggleModal} transactionType={transactionType}/>
    </div>
  )
}

export default AddTransaction