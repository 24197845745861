import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import PopOverHint from 'common/components/PopOverHint'
import style from './index.module.sass'

const Item = ({
  user,
  amount,
  className,
  label,
  startDate,
  endDate,
  periodBalance,
  percentage,
  hint,
}) => (
  <div className={className}>
    <div className={classNames(style.item)}>
      <div className={style.label}>
        {(user?.showHints) ? (<PopOverHint>
          {hint}
        </PopOverHint>) : null}
        {label}
      </div>
      <div className={style.counter}>
        <span>{amount} р.</span>
      </div>
      <div className={style.indicator}>
        {(periodBalance) ? (
          <div className={(periodBalance < 0) ? style.negative : style.positive}>
            {periodBalance} ({percentage}%)
          </div>
        ) : null}
        {(startDate && endDate) ? (<div>С {startDate} по {endDate}</div>) : null}
      </div>
    </div>
  </div>
)

Item.propTypes = {
  amount: PropTypes.number.isRequired,
  className: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  periodBalance: PropTypes.number,
  percentage: PropTypes.number,
}

Item.defaultProps = {
  startDate: null,
  endDate: null,
  periodBalance: null,
  percentage: null,
}

export default Item