import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getSelfUser, editSelfUser } from 'redux/authSlice'
import useSwipe from 'hooks/useSwipe'
import Header from './components/Header'
import Sidebar from './components/Sidebar'
import Content from './components/Content'
import style from './index.module.sass'

const Main = () => {
  const [isSidebarOpened, setSidebarOpened] = useState(false)

  useSwipe(() => (setSidebarOpened(true)), () => (setSidebarOpened(false)))

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getSelfUser())
  }, [dispatch])

  const {
    user,
  } = useSelector(state => ({
    user: state.auth.user,
  }))

  const { timeZone } = Intl.DateTimeFormat().resolvedOptions()

  useEffect(() => {
    if (user && (user.timezone !== timeZone)) {
      dispatch(editSelfUser({user: { timezone: timeZone }, tags: ['Transaction']}))
    }
  }, [dispatch, timeZone, user])

  return (
    <div className={style.main}>
      <Sidebar isSidebarOpened={isSidebarOpened} setSidebarOpened={setSidebarOpened} />
      <div className={style.pageInner}>
        <Header isSidebarOpened={isSidebarOpened} setSidebarOpened={setSidebarOpened} />
        <Content />
      </div>
    </div>
  )
}
export default Main
