import { configureStore } from '@reduxjs/toolkit'
import { isEmpty } from 'lodash'

import { transactionsSlice } from './transactionsSlice'
import {AuthSlice, loginThunk} from './authSlice'

const store = configureStore({
  reducer: {
    auth: AuthSlice.reducer,
    [transactionsSlice.reducerPath]: transactionsSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(transactionsSlice.middleware),
})

if (!isEmpty(localStorage.getItem('accessToken')) && !isEmpty(localStorage.getItem('refreshToken'))) {
  store.dispatch(loginThunk(localStorage.getItem('accessToken'), localStorage.getItem('refreshToken')))
}

export default store