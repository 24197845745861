/* eslint-disable react/prop-types */
import React, { useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { editSavingsUser } from 'redux/authSlice'
import { debounce } from 'lodash'
import style from './index.module.sass'

const Savings = ({ savings, setSavings}) => {
  const [localSavings, setLocalSavings] = useState(savings)

  const dispatch = useDispatch()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setUserSavingsDebounce = useCallback(debounce((value, currentSavings) => {
    if (value !== currentSavings) {
      dispatch(editSavingsUser(value))
      setSavings(value)
    }
  }
  , 1000), [])

  const onChange = (e) => {
    const {value} = e.target
    if ((value === '') || Number(value) >= 0 && Number(value) < 100 && (Number(value) === parseInt(Number(value), 10))) {
      setLocalSavings(value)
      if (value !== '') {
        setUserSavingsDebounce(Number(value), savings)
      }
    }
  }

  return (
    <div className={style.savings}>
      <input onChange={onChange} value={localSavings} type='number' />%
    </div>
  )
}

export default Savings
