import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { toLocaleDateString } from 'common/helpers'
import style from './index.module.sass'
import Item from './components/Item'

const Content = ({ dayRemainingMoney,
  dayEstimatedBalance,
  date,
  periodRemainingMoney,
  user,
}) => {
  const items = [
    {
      className: classNames(style.totalBalance, {
        [style.alarm]: dayRemainingMoney < 0,
      }),
      amount: dayRemainingMoney,
      label: `Баланс на ${toLocaleDateString(date)}:`,
      hint: (
        <div>
          Остаток на текущий день.<br />
          Считается по формуле:<br />
          Рассчитанный бюджет на сегодня - Ежедневные траты за сегодня + Ежедневные доходы за сегодня
        </div>
      ),
    },
    {
      className: style.income,
      amount: dayEstimatedBalance,
      label: `Рассчитаный бюджет на ${toLocaleDateString(date)}:`,
      hint: (
        <div>
          Рассчитаный бюджет на текущий день.<br />
          Значение на следующий день будет изменяться в зависимости от того, сколько вы потратите за сегодня. Если сегодня уйдете в минус, то завтра, рассчитаный бюджет будет меньше, если не потратите всю сумму, то больше.<br />
          Считается по формуле:<br />
          (Доходы за месяц - (Доходы за месяц * Процент откладывемых денег) - Расходы за месяц + (Ежедневные доходы до текущей даты за месяц - Ежедневные траты до текущей даты за месяц)) / Кол-во оставшихся дней в текущем месяце
        </div>
      ),
    },
    {
      className: style.outcome,
      amount: periodRemainingMoney,
      label: 'Баланс на текущий месяц:',
      hint: (
        <div>
          Остаток на текущий месяц <br />
          Считается по формуле:<br />
          Доходы за месяц - (Доходы за месяц * Процент откладывемых денег) - Расходы за месяц + (Ежедневные доходы до текущей даты за месяц - Ежедневные траты до текущей даты за месяц)
        </div>
      ),
    }
  ]

  return (
    <div className={style.content}>
      {items.map((item) => <Item key={item.label} user={user} {...item} />)}
    </div>
  )
}

Content.propTypes = {
  dayRemainingMoney: PropTypes.number.isRequired,
  dayEstimatedBalance: PropTypes.number.isRequired,
  date: PropTypes.string.isRequired,
  periodRemainingMoney: PropTypes.number.isRequired,
}

export default Content