import React from 'react'
import Block from 'components/Block'
import style from './index.module.sass'
import PieChart from './components/PieChart'
import BalanceDynamic from './components/BalanceDynamic'

const Charts = () => (
  <div className={style.charts}>
    <Block>
      <PieChart />
    </Block>
    <Block>
      <BalanceDynamic />
    </Block>
  </div>
)

export default Charts
