import React from 'react'
import Modal from 'common/components/Modal'
import PropTypes from 'prop-types'
import Form from './components/Form'

const TransactionsFilterModal = ({ transactionsFilter, setTransactionFilter, modalIsOpen, toggleModal }) => (
  <Modal
    onRequestClose={() => toggleModal(false)}
    isOpen={modalIsOpen}
  >
    <Form transactionsFilter={transactionsFilter} setTransactionFilter={setTransactionFilter} modalIsOpen={modalIsOpen} toggleModal={toggleModal} />
  </Modal >
)

TransactionsFilterModal.propTypes = {
  modalIsOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
}

export default TransactionsFilterModal