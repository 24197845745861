import React, { useState, useRef } from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import ru from 'date-fns/locale/ru'
import 'react-datepicker/dist/react-datepicker.css'
import useMedia, { DEVICES } from 'hooks/useMedia'
import CustomInput from './components/CustomInput'
import CustomHeader from './components/CustomHeader'

registerLocale('ru', ru)

const DateRangePicker = ({ dates, setDates, placeHolder }) => {
  const device = useMedia()

  const [localStartDate, setLocalStartDate] = useState(dates?.startDate)
  const [localEndDate, setLocalEndDate] = useState(dates?.endDate)

  const onChange = (datePickerDates) => {
    const [start, end] = datePickerDates
    setLocalStartDate(start)
    setLocalEndDate(end)

    if (start && end) {
      setDates({
        startDate: start,
        endDate: end
      })
    }
  }
  let datepickerRef = useRef(null)

  return (
    <DatePicker
      renderCustomHeader={({
        monthDate,
        decreaseMonth,
        increaseMonth
      }) => <CustomHeader datepickerRef={datepickerRef} onChange={onChange} monthDate={monthDate} decreaseMonth={decreaseMonth} increaseMonth={increaseMonth} />}
      customInput={<CustomInput startDate={localStartDate} endDate={localEndDate} placeHolder={placeHolder} />}
      // eslint-disable-next-line no-return-assign
      ref={(r) => datepickerRef = r}
      withPortal={device === DEVICES.MOBILE}
      // eslint-disable-next-line no-return-assign
      onFocus={(e) => e.target.readOnly = true}
      locale="ru"
      onChange={onChange}
      startDate={localStartDate}
      endDate={localEndDate}
      selectsRange
    />
  )
}

export default DateRangePicker