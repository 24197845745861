import { createApi } from '@reduxjs/toolkit/query/react'
import axiosBaseQuery from './baseQuery'

const apiSlice = createApi({
  reducerPath: 'postApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['Transaction', 'TransactionStats', 'Category', 'CategoryStats', 'TinkoffInvestment', 'Group'],
  endpoints: () => ({}),
})

export default apiSlice