import React from 'react'
import ReactDatePicker, { registerLocale } from 'react-datepicker'
import ru from 'date-fns/locale/ru'
import 'react-datepicker/dist/react-datepicker.css'
import useMedia, { DEVICES } from 'hooks/useMedia'
import CustomInput from './components/CustomInput'

registerLocale('ru', ru)

const DatePicker = ({value, setValueFn, showMonthYearPicker, customToLocaleDateStringOptions}) => {
  const device = useMedia()

  return (
    <ReactDatePicker
      customInput={<CustomInput date={value} customToLocaleDateStringOptions={customToLocaleDateStringOptions} />}
      // eslint-disable-next-line no-return-assign
      onFocus={(e) => e.target.readOnly = true}
      withPortal={device === DEVICES.MOBILE}
      locale="ru"
      selected={value}
      onChange={setValueFn}
      showMonthYearPicker={showMonthYearPicker}
    />
  )
}

export default DatePicker
