import apiSlice from './apiSlice'

export const transactionsStatsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPeriodStatistic: builder.query({
      query: (params) => ({
        url: 'accountant/transactions/overview-period-statistic/',
        params
      }),
      providesTags: () => [{ type: 'TransactionStats', id: 'periodStatistic' }],
    }),
    getBalanceDynamic: builder.query({
      query: (params) => ({
        url: 'accountant/transactions/balance-dynamic/',
        params
      }),
      providesTags: () => [{ type: 'TransactionStats', id: 'balanceDynamic' }],
    }),
    getBudjet: builder.query({
      query: (params) => ({
        url: 'accountant/transactions/overview-budjet/',
        params
      }),
      providesTags: () => [{ type: 'TransactionStats', id: 'budjet' }],
    }),
  }),
  overrideExisting: false,
})

export const {useGetBalanceDynamicQuery, useGetBudjetQuery, useGetPeriodStatisticQuery } = transactionsStatsSlice