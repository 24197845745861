import React from 'react'
import PropTypes from 'prop-types'

const SortIcon = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
    <g transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
      <path d="M10 545 c0 -13 24 -15 160 -15 136 0 160 2 160 15 0 13 -24 15 -160 15 -136 0 -160 -2 -160 -15z" />
      <path d="M10 395 c0 -13 24 -15 160 -15 136 0 160 2 160 15 0 13 -24 15 -160 15 -136 0 -160 -2 -160 -15z" />
      <path d="M10 245 c0 -13 24 -15 160 -15 136 0 160 2 160 15 0 13 -24 15 -160 15 -136 0 -160 -2 -160 -15z" />
      <path d="M10 95 c0 -13 24 -15 160 -15 136 0 160 2 160 15 0 13 -24 15 -160 15 -136 0 -160 -2 -160 -15z" />
    </g>
  </svg>
)

SortIcon.propTypes = {
  className: PropTypes.string,
}

SortIcon.defaultProps = {
  className: null
}

export default SortIcon
