import React from 'react'
import PropTypes from 'prop-types'
import Item from './components/Item'
import style from './index.module.sass'

const Content = (
  {
    user,
    startDate,
    endDate,
    periodIncome,
    periodOutcome,
    periodBalance,
    totalBalance,
    percentage,
  }
) => {
  const items = [
    {
      className: style.totalBalance,
      amount: totalBalance,
      label:  `Баланс на ${endDate}:`,
      periodBalance,
      percentage,
      hint: (
        <div>
          Баланс по всем вашим транзациям (ежедневным, ежемесячным, коррекциям) на последнюю дату в выбранном периоде. <br/>
          Также, в нижней части этого блока, отображается изменение баланса за выбранный период.<br/>
          Если брать большой период, например, 3 месяца, то вы сможете увидеть как изменился ваш баланс на дистанции.<br/>
          Важно также понимать, что данный блок учитывает транзакции только по последнюю дату в выбранном периоде,
          то есть транзакции которые запранированны на будущие даты, в балансе учтены не будут (например, начисление запрлаты или списание за ЖКХ).
          Это было реализовано специально, чтобы можно было увидеть свой баланс в моменте.
        </div>
      ),
    },
    {
      className: style.income,
      amount: periodIncome,
      label: `Доход c ${startDate} по ${endDate}:`,
      hint: (
        <div>
          Доход за выбранный период<br/>
          Считается по формуле:<br/>
          Месячные доходы за период + Ежедневные доходы за период
        </div>
      ),
    },
    {
      className: style.outcome,
      amount: periodOutcome,
      label: `Расходы c ${startDate} по ${endDate}:`,
      hint: (
        <div>
          Расходы за выбранный период<br/>
          Считается по формуле:<br/>
          Месячные расходы за период + Ежедневные расходы за период
        </div>
      ),
    }
  ]

  return (
    <div className={style.content}>
      {items.map((item) => (
        <Item key={item.label} user={user} {...item} startDate={startDate} endDate={endDate} />
      ))}
    </div>
  )
}

Content.propTypes = {
  periodIncome: PropTypes.number.isRequired,
  periodOutcome: PropTypes.number.isRequired,
  periodBalance: PropTypes.number,
  totalBalance: PropTypes.number.isRequired,
  percentage: PropTypes.number,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
}

Content.defaultProps = {
  periodBalance: null,
  percentage: null
}

export default Content
