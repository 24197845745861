import React from 'react'
import classNames from 'classnames'
import ReactModal from 'react-modal'
import style from './index.module.sass'

const Modal = ({ children, isOpen, onRequestClose, className }) => (
  <ReactModal
    ariaHideApp={false}
    className={classNames(style.modal, {
      [className]: className
    })}
    onRequestClose={() => onRequestClose(false)}
    isOpen={isOpen}
    closeTimeoutMS={200}
    overlayClassName={style.overlayClassName}
  >
    {children}
  </ReactModal>
)
export default Modal
