/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import Loader from 'components/Loader'
import { useGetPeriodStatisticQuery } from 'redux/transactionsStatsSlice'
import { toLocaleDateStringOptions } from 'common/constants'
import PopOverHint from 'common/components/PopOverHint'
import DateRangePicker from 'common/components/DateRangePicker'
import Error from 'components/Error'
import style from './index.module.sass'
import Content from './components/Content'

const PeriodStatistic = () => {
  const [dates, setDates] = useState(
    {
      startDate: moment().startOf('month').toDate(),
      endDate: moment().toDate(),
    }
  )

  const {
    user,
  } = useSelector(state => ({
    user: state.auth.user,
  }))

  const {
    data,
    error,
    isFetching,
  } = useGetPeriodStatisticQuery({ date__gte: moment(dates.startDate).format('YYYY-MM-DD'), date__lte: moment(dates.endDate).format('YYYY-MM-DD') })

  const render = () => {
    if (isFetching) return <Loader />
    if (error) return <Error error={error} />
    return <Content user={user} startDate={dates.startDate.toLocaleDateString('ru-ru', toLocaleDateStringOptions)} endDate={dates.endDate.toLocaleDateString('ru-ru', toLocaleDateStringOptions)} {...data} />
  }

  return (
    <div className={style.periodStatistic}>
      <div className={style.header}>
        <div className={style.title}>
          Статистика по периодам
          {(user?.showHints) ? (
            <PopOverHint>
              Помогает посмотреть статистику по выбранному периоду.<br />
              По умолчанию выбирается текущий месяц, в блоке справа вы можете выбрать любой другой период.<br /> Советуем смотреть статистику кратную месяцам, так данные будут максимально информативными.
            </PopOverHint>
          ) : null}
        </div>
        <div className={style.filters}>
          <DateRangePicker
            placeHolder='Выберите даты'
            dates={dates}
            setDates={setDates}
          />
        </div>
      </div>
      {render()}
    </div>
  )
}

export default PeriodStatistic