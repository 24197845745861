import React, { useState, useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useGetBalanceDynamicQuery } from 'redux/transactionsStatsSlice'
import DatePicker from 'common/components/DatePicker'
import Error from 'components/Error'
import Loader from 'components/Loader'
import moment from 'moment'
import PopOverHint from 'common/components/PopOverHint'
import Chart from './components/Chart'
import style from './index.module.sass'

const BalanceDynamic = () => {
  const {
    user,
  } = useSelector(state => ({
    user: state.auth.user,
  }))

  const [endDate, setEndDate] = useState(moment().toDate())
  const [isLoading, setIsLoading] = useState(true)

  const toLocaleDateStringOptions = {
    month: 'short',
  }

  const {
    data,
    error,
    isFetching,
  } = useGetBalanceDynamicQuery({
    date__lte: moment(endDate).endOf('month').format('YYYY-MM-DD')
  })

  useEffect(() => {
    if (!isFetching) {
      setIsLoading(false)
    }
  }, [isFetching])

  const render = useCallback(() => {
    if (isLoading) return <Loader />
    if (error) return <Error error={error} />
    return <Chart monthData={data} />
  }, [isLoading, error, data])

  return (
    <div className={style.balanceDynamic}>
      <div className={style.header}>
        <div className={style.title}>
          Динамика баланса
          {(user?.showHints) ? (
            <PopOverHint>
              Первой датой в периоде берется месяц первой транзакции, второй - по умолчанию текущий месяц.<br />
              Данный график показывает динамику вашего баланса по месяцам, за расчетные даты берется конец месяца.<br />
              Если месяц ещё не закончился, то система прогнозирует баланс на основе уже имеющихся данных с помощью линейной регрессии<br />
              Чем больше вы будете пользоваться приложением, тем точнее будет прогноз.
            </PopOverHint>
          ) : null}
        </div>
        <div className={style.filters}>
          <DatePicker
            value={endDate}
            setValueFn={setEndDate}
            customToLocaleDateStringOptions={toLocaleDateStringOptions}
            showMonthYearPicker
          />
        </div>
      </div>
      {render()}
    </div>
  )
}

export default BalanceDynamic