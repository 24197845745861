/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { editSelfUser } from 'redux/authSlice'
import style from './index.module.sass'

const AddNewToken = ({ error }) => {

  const [token, setToken] = useState('')

  const dispatch = useDispatch()

  const addNewTokenOnClick = () => {
    if (token) {
      dispatch(editSelfUser({ user: { tinkoff_invest_token: token }, tags: ['TinkoffInvestment'] }))
      setToken('')
    }
  }

  return (
    <div>
      <div>{error?.data || 'Привяжите токен для интеграции'}</div>
      <div className={style.addNewToken}>
        <input value={token} onChange={(event) => setToken(event.target.value)} />
        <button className={style.button} type='button' onClick={addNewTokenOnClick}>Привязать токен</button>
      </div>
    </div>
  )
}

export default AddNewToken
