import React from 'react'
import Select from 'react-select'
import PropTypes from 'prop-types'
import { useGetCategoriesQuery } from 'redux/categoriesSlice'
import useMedia, { DEVICES } from 'hooks/useMedia'
import Error from 'components/Error'
import { categoryDefaultOption } from 'common/constants'
import style from './index.module.sass'

const Category = ({ handleChange, form, fieldId }) => {
  const device = useMedia()

  const {
    data,
    error,
    isFetching,
  } = useGetCategoriesQuery({ enum_type: form.enumType.value, ordering: '-transactions_count' })

  const categoriesData = (isFetching) ? [] : data.results.map((category) => ({
    label: category?.title,
    value: category?.id,
  }))

  const categories = [categoryDefaultOption].concat(categoriesData)

  return (
    <div className={style.categorySelect}>
      <label htmlFor={fieldId}>Категория</label>
      {(error) ? <Error error={error} /> : (
        <Select
          value={form[fieldId]}
          isLoading={isFetching}
          inputId={fieldId}
          isSearchable={device !== DEVICES.MOBILE}
          defaultValue={form[fieldId]}
          onChange={handleChange(fieldId)}
          options={categories}
          styles={{
            option: (styles) => ({
              ...styles,
              backgroundColor: null,
              color: 'black !important',
              '&:hover': {
                backgroundColor: '#6FA3D3',
              },
            }),
            menu: (styles) => ({
              ...styles,
              maxHeight: '200px'
            }),
            menuList: (styles) => ({
              ...styles,
              maxHeight: '200px'
            }),
          }}
        />
      )}
    </div>
  )
}

Category.propTypes = {
  handleChange: PropTypes.func.isRequired,
  fieldId: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  form: PropTypes.object.isRequired
}

export default Category
