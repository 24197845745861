import React from 'react'
import PropTypes from 'prop-types'

const CategoriesIcon = ({ className }) => (
  <svg width="1em" height="1em" viewBox="0 0 512 512" className={className} preserveAspectRatio="xMidYMid meet">
    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
      <path d="M266 5024 c-34 -11 -64 -31 -102 -68 -89 -90 -84 -21 -84 -1117 0 -1094 -5 -1024 85 -1114 90 -91 19 -86 1135 -83 l975 3 50 27 c59 31 94 67 126 128 l24 45 3 975 c3 1116 8 1045 -83 1135 -90 90 -19 85 -1119 84 -824 0 -968 -3 -1010 -15z m2016 -182 l33 -32 0 -970 0 -970 -33 -32 -32 -33 -970 0 -970 0 -32 33 -33 32 0 969 0 969 25 27 c14 15 34 31 45 36 11 4 451 7 977 6 l958 -2 32 -33z" />
      <path d="M1165 4713 c-60 -8 -181 -42 -239 -68 -482 -213 -670 -804 -400 -1257 65 -108 194 -237 302 -302 346 -206 789 -151 1073 133 112 112 191 253 235 417 27 103 26 312 -3 419 -92 340 -362 591 -698 650 -81 14 -198 17 -270 8z m247 -167 c102 -20 175 -49 260 -105 101 -66 196 -172 247 -274 61 -124 76 -190 75 -337 -1 -107 -5 -137 -27 -205 -38 -115 -90 -198 -182 -290 -92 -92 -175 -144 -290 -182 -68 -22 -98 -26 -205 -27 -147 -1 -213 14 -337 75 -145 72 -287 228 -343 377 -77 204 -59 439 47 622 47 80 178 213 256 259 152 89 328 120 499 87z" />
      <path d="M1225 4215 c-14 -13 -25 -31 -25 -40 0 -12 -12 -15 -55 -15 -46 0 -60 -4 -80 -25 -24 -23 -25 -28 -25 -175 0 -147 1 -152 25 -175 23 -24 29 -25 160 -25 l135 0 0 -40 0 -40 -135 0 c-131 0 -137 -1 -160 -25 -33 -32 -33 -78 0 -110 19 -20 34 -25 77 -25 50 0 54 -2 68 -33 18 -38 58 -55 98 -41 15 5 33 23 42 41 14 31 18 33 68 33 43 0 58 5 77 25 24 23 25 28 25 175 0 147 -1 152 -25 175 -23 24 -29 25 -160 25 l-135 0 0 40 0 40 135 0 c131 0 137 1 160 25 16 15 25 36 25 55 0 51 -38 80 -105 80 -43 0 -55 3 -55 15 0 27 -47 65 -80 65 -19 0 -40 -9 -55 -25z" />
      <path d="M2826 5024 c-34 -11 -64 -31 -102 -68 -89 -90 -84 -21 -84 -1117 0 -1094 -5 -1024 85 -1114 90 -91 19 -86 1135 -83 l975 3 50 27 c59 31 94 67 126 128 l24 45 3 975 c3 1116 8 1045 -83 1135 -90 90 -19 85 -1119 84 -824 0 -968 -3 -1010 -15z m2016 -182 l33 -32 0 -970 0 -970 -33 -32 -32 -33 -970 0 -970 0 -32 33 -33 32 0 969 0 969 25 27 c14 15 34 31 45 36 11 4 451 7 977 6 l958 -2 32 -33z" />
      <path d="M266 2464 c-34 -11 -64 -31 -102 -68 -89 -90 -84 -21 -84 -1117 0 -1094 -5 -1024 85 -1114 90 -91 19 -86 1135 -83 l975 3 50 27 c59 31 94 67 126 128 l24 45 3 975 c3 1116 8 1045 -83 1135 -90 90 -19 85 -1119 84 -824 0 -968 -3 -1010 -15z m2016 -182 l33 -32 0 -970 0 -970 -33 -32 -32 -33 -970 0 -970 0 -32 33 -33 32 0 969 0 969 25 27 c14 15 34 31 45 36 11 4 451 7 977 6 l958 -2 32 -33z" />
      <path d="M2826 2464 c-34 -11 -64 -31 -102 -68 -89 -90 -84 -21 -84 -1116 0 -1094 -5 -1026 83 -1114 92 -92 19 -87 1137 -84 l975 3 50 27 c59 31 94 67 126 128 l24 45 3 975 c3 1118 8 1045 -84 1137 -88 88 -19 83 -1118 82 -824 0 -968 -3 -1010 -15z m2016 -182 l33 -32 0 -970 0 -970 -33 -32 -32 -33 -970 0 -970 0 -32 33 -33 32 0 969 0 969 25 27 c14 15 34 31 45 36 11 4 451 7 977 6 l958 -2 32 -33z" />
      <path d="M3706 1984 c-34 -11 -64 -31 -102 -68 -63 -64 -84 -122 -84 -238 l0 -78 -95 0 c-88 0 -98 -2 -120 -25 -14 -13 -25 -32 -25 -42 0 -10 -18 -232 -40 -493 -22 -261 -40 -483 -40 -493 0 -10 11 -29 25 -42 l24 -25 591 0 591 0 24 25 c14 13 25 32 25 42 0 10 -18 232 -40 493 -22 261 -40 483 -40 493 0 10 -11 29 -25 42 -22 23 -32 25 -120 25 l-95 0 0 79 c0 112 -22 174 -85 236 -35 36 -66 56 -104 68 -66 21 -197 21 -265 1z m256 -182 c31 -31 33 -37 37 -117 l3 -85 -162 0 -162 0 3 84 c4 73 7 88 29 111 36 39 56 45 142 42 73 -2 80 -4 110 -35z m-442 -417 c0 -67 29 -105 80 -105 51 0 80 38 80 105 l0 55 160 0 160 0 0 -55 c0 -67 29 -105 80 -105 51 0 80 38 80 105 l0 55 45 0 c40 0 45 -3 45 -22 0 -13 14 -183 30 -378 16 -195 30 -365 30 -377 l0 -23 -470 0 -470 0 0 23 c0 12 14 182 30 377 16 195 30 365 30 378 0 19 5 22 45 22 l45 0 0 -55z" />
    </g>
    <g fill="currentColor"><path fillRule="evenodd" d="M24 42c9.941 0 18-8.059 18-18S33.941 6 24 6S6 14.059 6 24s8.059 18 18 18Zm0 2c11.046 0 20-8.954 20-20S35.046 4 24 4S4 12.954 4 24s8.954 20 20 20Z" clipRule="evenodd" /><path d="M12 35.63c0-1.033.772-1.906 1.8-2.02c7.715-.854 12.72-.777 20.418.019a1.99 1.99 0 0 1 1.108 3.472c-9.085 7.919-14.277 7.81-22.686.008c-.41-.38-.64-.92-.64-1.478Z" /><path fillRule="evenodd" d="M34.115 34.623c-7.637-.79-12.57-.864-20.206-.019A1.028 1.028 0 0 0 13 35.631c0 .286.119.557.32.745c4.168 3.866 7.326 5.613 10.413 5.624c3.098.011 6.426-1.722 10.936-5.652a.99.99 0 0 0-.554-1.724ZM13.69 32.616c7.796-.863 12.874-.785 20.632.018a2.99 2.99 0 0 1 1.662 5.221c-4.575 3.988-8.385 6.16-12.257 6.145c-3.883-.014-7.525-2.223-11.766-6.158A3.018 3.018 0 0 1 11 35.63a3.028 3.028 0 0 1 2.69-3.015Z" clipRule="evenodd" /><path d="M32 20a8 8 0 1 1-16 0a8 8 0 0 1 16 0Z" /><path fillRule="evenodd" d="M24 26a6 6 0 1 0 0-12a6 6 0 0 0 0 12Zm0 2a8 8 0 1 0 0-16a8 8 0 0 0 0 16Z" clipRule="evenodd" /></g>
  </svg>
)

CategoriesIcon.propTypes = {
  className: PropTypes.string,
}

CategoriesIcon.defaultProps = {
  className: null
}

export default CategoriesIcon
