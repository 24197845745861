import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import PopOverHint from 'common/components/PopOverHint'
import style from './index.module.sass'

const Item = ({
  user,
  amount,
  className,
  label,
  hint,
}) => (
  <div className={className}>
    <div className={classNames(style.item)}>
      <div className={style.label}>
        {(user?.showHints) ? (<PopOverHint>
          {hint}
        </PopOverHint>) : null}
        {label}
      </div>
      <div className={style.counter}>
        {amount} р.
      </div>
    </div>
  </div>
)

Item.propTypes = {
  amount: PropTypes.number.isRequired,
  className: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
}

export default Item