import React from 'react'
import Modal from 'common/components/Modal'
import Form from './components/Form'

const ModalCategory = ({ modalIsOpen, toggleModal, category }) => (
  <Modal
    onRequestClose={() => toggleModal(false)}
    isOpen={modalIsOpen}
  >
    <Form category={category} toggleModal={toggleModal} />
  </Modal>
)

export default ModalCategory