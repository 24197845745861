import React from 'react'
import PropTypes from 'prop-types'
import Item from './components/Item'
import style from './index.module.sass'
import DashboardIcon from './NavigationIcones/DashboardIcon'
import AccountIcon from './NavigationIcones/AccountIcon'
import GraphIcon from './NavigationIcones/GraphIcon'
import TransactionsIcon from './NavigationIcones/TransactionsIcon'
import CategoriesIcon from './NavigationIcones/CategoriesIcon'

const Nav = ({ isSidebarOpened }) => (
  <nav className={style.nav}>
    <ul>
      <Item
        isSidebarOpened={isSidebarOpened}
        name='Главная'
        icon={<DashboardIcon />}
        link="/"
      />
      <Item
        isSidebarOpened={isSidebarOpened}
        name='Транзакции'
        icon={<TransactionsIcon />}
        link="/transactions"
      />
      <Item
        isSidebarOpened={isSidebarOpened}
        name='Категории'
        icon={< CategoriesIcon />}
        link="/categories"
      />
      <Item
        isSidebarOpened={isSidebarOpened}
        name='Графики'
        icon={<GraphIcon />}
        link="/charts"
      />
      <Item
        isSidebarOpened={isSidebarOpened}
        name='Мой аккаунт'
        icon={<AccountIcon />}
        link="/account"
      />
    </ul>
  </nav>
)

Nav.propTypes = {
  isSidebarOpened: PropTypes.bool.isRequired,
}

export default Nav