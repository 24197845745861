import React from 'react'
import { InView } from 'react-intersection-observer'
import classNames from 'classnames'
import { getTypeStr, toLocaleDateString } from 'common/helpers'
import { enumType as enumTypeConst } from 'common/constants'
import style from './index.module.sass'
import DeleteIcon from './ButtonsIcons/DeleteIcon'
import EditIcon from './ButtonsIcons/EditIcon'

const TableCategory = ({
  index,
  id,
  enumType,
  color,
  title,
  created,
  toggleModalCategory,
  toggleModalCategoryDelete,
}) => (
  <InView>
    {({ inView, ref }) => (
      <div ref={ref} className={classNames(style.flexTable, style.category)} role="rowgroup">
        {(inView) ?
          (<>
            <div className={`${style.flexRow} ${style.indexCol}`} role="cell">
              <span>№:</span>
              {` ${index + 1}`}
            </div>
            <div className={
              classNames(
                style.flexRow,
                style.enumTypeCol,
                {
                  [style.income]: enumType === enumTypeConst.INCOME,
                  [style.outcome]: enumType === enumTypeConst.OUTCOME,
                }
              )
            } role="cell">
              <span>Тип: </span>
              {getTypeStr(enumType)}
            </div>
            <div className={`${style.flexRow} ${style.titleCol}`} role="cell">
              <span>Название: </span>
              {title}
            </div>
            <div className={`${style.flexRow} ${style.colorCol}`} role="cell">
              <span>Цвет: </span>
              <div style={{ backgroundColor: color }} className={style.colorBlock} />
            </div>
            <div className={`${style.flexRow} ${style.createdCol}`} role="cell">
              <span>Дата создания: </span>
              {toLocaleDateString(created)}
            </div>
            <div className={`${style.flexRow} ${style.buttonsCol}`} role="cell">
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <button
                onClick={() => toggleModalCategory({
                  isOpened: true, category: {
                    id,
                    enumType,
                    color,
                    title,
                  }
                })}
                type="button"><EditIcon /></button>
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <button type="button"
                onClick={() => toggleModalCategoryDelete({
                  isOpened: true, category: {
                    id,
                    enumType,
                    color,
                    title,
                    created,
                  }
                })}
              >
                <DeleteIcon />
              </button>
            </div>
          </>)
          : null}
      </div>
    )}
  </InView>
)

export default TableCategory
