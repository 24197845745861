import React from 'react'
import Select from 'react-select'
import PropTypes from 'prop-types'
import { categoryDefaultOption, enumTypeOptions } from 'common/constants'

const EnumType = ({ form, setForm }) => (
  <div>
    <label htmlFor='enumType'>Тип категории</label>
    <Select
      placeholder="Тип категории"
      inputId='enumType'
      isSearchable={false}
      defaultValue={form.enumType}
      onChange={(value) => {
        setForm({
          ...form,
          enumType: value,
          category: categoryDefaultOption,
        })
      }}
      options={enumTypeOptions}
      styles={{
        option: (styles) => ({
          ...styles,
          backgroundColor: null,
          color: 'black !important',
          '&:hover': {
            backgroundColor: '#6FA3D3',
          },
        }),
      }}
    />
  </div>
)

EnumType.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  form: PropTypes.object.isRequired,
  setForm: PropTypes.func.isRequired,
}

export default EnumType
