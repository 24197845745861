import apiSlice from './apiSlice'

export const transactionsStatsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPieChart: builder.query({
      query: (params) => ({
        url: 'accountant/categories/charts-pie/',
        params
      }),
      providesTags: () => [{ type: 'CategoryStats', id: 'pieChart' }],
    }),
  }),
  overrideExisting: false,
})

export const { useGetPieChartQuery } = transactionsStatsSlice