import React from 'react'
import style from './index.module.sass'

const UserAvatar = ({ user }) => {
  const content = (user.photoUrl) ? <img src={user.photoUrl} alt={user.firstName} /> : user.firstName.charAt(0).toUpperCase()

  return (user.username) ? (
    <a
      href={`https://t.me/${user.username}`}
      className={style.userAvatar}
      style={{ backgroundColor: user.color }}
    >
      {content}
    </a>
  ) : (
    <div
      className={style.userAvatar}
      style={{ backgroundColor: user.color }}
    >
      {content}
    </div>
  )
}

export default UserAvatar