/* eslint-disable react/forbid-prop-types */
import React from 'react'
import Select from 'react-select'
import PropTypes from 'prop-types'

const EnumTypeSelect = ({ className, enumType, setEnumType, enumTypeOptions }) => (
  <div className={className}>
    <Select
      isSearchable={false}
      defaultValue={enumType}
      onChange={setEnumType}
      options={enumTypeOptions}
      styles={{
        option: (styles) => ({
          ...styles,
          backgroundColor: null,
          color: 'black',
          '&:hover': {
            backgroundColor: '#6FA3D3',
          },
        }),
      }}
    />
  </div>
)

EnumTypeSelect.propTypes = {
  className: PropTypes.string,
  enumType: PropTypes.object.isRequired,
  enumTypeOptions: PropTypes.array.isRequired,
  setEnumType: PropTypes.func.isRequired
}

EnumTypeSelect.defaultProps ={
  className: null
}

export default EnumTypeSelect