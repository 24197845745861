import React from 'react'
import Modal from 'common/components/Modal'
import DeleteTransaction from './components/DeleteTransaction'

const ModalDeleteTransaction = ({ modalIsOpen, toggleModal, transaction }) => (
  <Modal
    onRequestClose={() => toggleModal(false)}
    isOpen={modalIsOpen}
  >
    <DeleteTransaction transaction={transaction} toggleModal={toggleModal} />
  </Modal >
)

export default ModalDeleteTransaction