export const enumType = {
  INCOME: 1,
  OUTCOME: 2,
}

export const enumTypeOptions = [
  {
    value: enumType.OUTCOME,
    label: 'Расход'
  },
  {
    value: enumType.INCOME,
    label: 'Доход'
  },
]

export const enumKind = {
  DAILY: 1,
  MONTHLY: 2,
  CORRECTION: 3,
}

export const enumKindOptions = [
  {
    value: enumKind.DAILY,
    label: 'Обычная'
  },
  {
    value: enumKind.MONTHLY,
    label: 'Ежемесячная'
  },
  {
    value: enumKind.CORRECTION,
    label: 'Коррекция'
  },
]

export const categoryDefaultOption = {
  label: 'Без категории',
  value: null
}

export const toLocaleDateStringOptions = {
  month: 'short',
  day: 'numeric'
}

export const defaultTransactionFilter = {
  enumTypes: [],
  enumKinds: [],
  categories: [],
  amountLte: null,
  amountGte: null,
  dateLte: null,
  dateGte: null,
  titleIn: '',
  ordering: '-date',
}

export const defaultCategoryFilter = {
  ordering: null,
}