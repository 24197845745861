import React from 'react'
import PropTypes from 'prop-types'

const SortUpIcon = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
    <g transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
      <path d="M10 545 c0 -13 24 -15 160 -15 136 0 160 2 160 15 0 13 -24 15 -160 15 -136 0 -160 -2 -160 -15z" />
      <path d="M462 507 c-47 -47 -64 -77 -45 -77 4 0 24 17 45 37 l38 37 0 -199 c0 -196 8 -249 30 -215 5 9 10 101 10 215 l0 199 38 -37 c40 -39 52 -45 52 -24 0 12 -99 117 -111 117 -2 0 -28 -24 -57 -53z" />
      <path d="M10 395 c0 -13 24 -15 160 -15 136 0 160 2 160 15 0 13 -24 15 -160 15 -136 0 -160 -2 -160 -15z" />
      <path d="M10 245 c0 -13 24 -15 160 -15 136 0 160 2 160 15 0 13 -24 15 -160 15 -136 0 -160 -2 -160 -15z" />
      <path d="M10 95 c0 -13 24 -15 160 -15 136 0 160 2 160 15 0 13 -24 15 -160 15 -136 0 -160 -2 -160 -15z" />
    </g>
  </svg>
)

SortUpIcon.propTypes = {
  className: PropTypes.string,
}

SortUpIcon.defaultProps = {
  className: null
}

export default SortUpIcon
