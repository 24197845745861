import React, { useState } from 'react'
import Block from 'components/Block'
import TransactionsTable from 'common/components/TransactionsTable'
import TransactionsFilter from 'common/components/TransactionsFilter'
import style from './index.module.sass'

const Transactions = ({transactionsFilter, setTransactionFilter}) => {
  const [transactionsCount, setTransactionsCount] = useState(0)

  return (
    <Block className={style.transactionsBlock}>
      <div className={style.transactions}>
        <div className={style.header}>
          <div className={style.title}>
            Все транзакции
          </div>
          <div className={style.rightHeader}>
            <div className={style.count}>Кол-во: <b>{transactionsCount}</b></div>
            <TransactionsFilter className={style.transactionsFilter} transactionsFilter={transactionsFilter} setTransactionFilter={setTransactionFilter} />
          </div>
        </div>
        <div className={style.content}>
          <TransactionsTable setTransactionFilter={setTransactionFilter} setTransactionsCount={ (count) => setTransactionsCount(count)} transactionsFilter={transactionsFilter} defaultLimit={20} />
        </div>
      </div>
    </Block>
  )
}

export default Transactions
