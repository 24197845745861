import React from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Pie } from 'react-chartjs-2'
import { toLocaleDateString } from 'common/helpers'
import PropTypes from 'prop-types'
import style from './index.module.sass'

ChartJS.register(ArcElement, Tooltip, Legend)

const Chart = ({ categories, dates }) => {

  const data = {
    labels: categories.map((category) => category.title),
    datasets: [
      {
        label: '# of Votes',
        data: categories.map((category) => category.totalAmount),
        backgroundColor: categories.map((category) => category.color),
        borderWidth: 1,
      },
    ],
  }

  return (
    <div className={style.content}>
      <div className={style.dates}>
        {(dates.value) ?
          `C ${toLocaleDateString(dates.value.transactionStartDate)} по ${toLocaleDateString(dates.value.transactionEndDate)}:`
          : 'За все время:'}
      </div>
      <div className={style.chart}>
        <Pie data={data} />
      </div>
    </div>
  )
}

Chart.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string,
      created: PropTypes.string,
      title: PropTypes.string,
      enumType: PropTypes.number,
      id: PropTypes.number,
      totalAmount: PropTypes.number,
    })
  ).isRequired,
  dates: PropTypes.shape({
    value: PropTypes.shape({
      transactionStartDate: PropTypes.string,
      transactionEndDate: PropTypes.string
    }),
    label: PropTypes.string
  }).isRequired,
}

export default Chart