import React, { useState } from 'react'
import { useAddNewTransactionMutation, useEditTransactionMutation } from 'redux/transactionsSlice'
import moment from 'moment'
import { isEqual } from 'lodash'
import { categoryDefaultOption, enumKindOptions, enumTypeOptions } from 'common/constants'
import style from './index.module.sass'
import EnumType from './components/EnumType'
import Amount from './components/Amount'
import Category from './components/Category'
import Date from './components/Date'
import Title from './components/Title'
import EnumKind from './components/EnumKind'

const Form = ({ toggleModal, transaction, transactionType }) => {
  const [addNewTransaction] = useAddNewTransactionMutation()
  const [editTransaction] = useEditTransactionMutation()

  const [form, setForm] = useState((transaction) ? {
    enumType: enumTypeOptions.find((option) => option.value === transaction.enumType),
    amount: transaction.amount,
    category: (transaction.category) ? {
      label: transaction.category.title,
      value: transaction.category.id,
    } : categoryDefaultOption,
    date: moment(transaction.date, 'YYYY-MM-DD').toDate(),
    enumKind: enumKindOptions.find((option) => option.value === transaction.enumKind),
    title: transaction.title || '',
  } : {
    enumType: (transactionType) ? enumTypeOptions.find((option) => option.value === transactionType) : enumTypeOptions[0],
    amount: 0,
    category: categoryDefaultOption,
    date: moment().toDate(),
    enumKind: enumKindOptions[0],
    title: ''
  })

  const handleChange = (fieldId) => (value) => {
    if (value?.target) {
      return setForm({
        ...form,
        [fieldId]: value.target.value,
      })
    }
    return setForm({
      ...form,
      [fieldId]: value,
    })
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    const formData = {
      enum_type: form.enumType.value,
      enum_kind: form.enumKind.value,
      amount: form.amount,
      category: form.category.value,
      date: moment(form.date).format('YYYY-MM-DD'),
      title: form.title || null,
    }
    if (transaction) {
      const transactionData = {
        enum_type: transaction.enumType,
        enum_kind: transaction.enumKind,
        amount: transaction.amount,
        category: (transaction?.category) ? transaction?.category.id : null,
        date: moment(transaction.date).format('YYYY-MM-DD'),
        title: transaction.title || null,
      }
      const { id } = transaction
      if (!isEqual(transactionData, formData)) editTransaction({transactionId: id, formData})
    }
    else {
      addNewTransaction(formData)
    }
    toggleModal(false)
  }

  return (
    <form onSubmit={handleSubmit} className={style.form}>
      <EnumKind handleChange={handleChange} form={form} setForm={setForm} fieldId="enumKind" />
      <EnumType handleChange={handleChange} form={form} setForm={setForm} fieldId="enumType" />
      <Amount handleChange={handleChange} form={form} setForm={setForm} fieldId="amount" />
      <Category handleChange={handleChange} form={form} setForm={setForm} fieldId="category" />
      <Date handleChange={handleChange} form={form} setForm={setForm} fieldId="date" />
      <Title handleChange={handleChange} form={form} setForm={setForm} fieldId="title" />
      <button className={style.button} type="submit">{(transaction) ? 'Изменить' : 'Добавить'}</button>
    </form>
  )
}

export default Form
