import React from 'react'
import style from './index.module.sass'

const AmountMulti = ({ handleChange, form, amountLteFieldId, amountGteFieldId }) => (
  <div className={style.amountMulti}>
    <div className={style.header}>Сумма транзакции</div>
    <div className={style.content}>
      <div className={style.amountGte}>
        <input type='number' defaultValue={form[amountGteFieldId]} onChange={handleChange(amountGteFieldId)} placeholder='от, р.' />
      </div>
      <div className={style.amountLte}>
        <input type='number' defaultValue={form[amountLteFieldId]} onChange={handleChange(amountLteFieldId)} placeholder='до, р.' />
      </div>
    </div>
  </div>
)

export default AmountMulti
