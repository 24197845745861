import apiSlice from './apiSlice'

export const categoriesSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCategories: builder.query({
      query: (params) => ({
        url: 'accountant/categories/',
        params
      }),
      providesTags: (result = {}) => [
        'Category',
        ...result.results.map(({ id }) => ({ type: 'Category', id })),
      ],
    }),
    getCategory: builder.query({
      query: (categoryId) => ({
        url:  `accountant/categories/${categoryId}`,
      }),
      providesTags: (result, error, arg) => [{ type: 'Category', id: arg.id }],
    }),
    addNewCategory: builder.mutation({
      query: (formData) => ({
        url: 'accountant/categories/',
        method: 'POST',
        data: formData,
      }),
      invalidatesTags: ['Category', 'CategoryStats'],
    }),
    deleteCategory: builder.mutation({
      query: (categoryId) => ({
        url: `accountant/categories/${categoryId}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Category', 'CategoryStats'],
    }),
    editCategory: builder.mutation({
      query: ({categoryId, formData}) => ({
        url: `accountant/categories/${categoryId}/`,
        method: 'PUT',
        data: formData,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Category', id: arg.id }, 'CategoryStats'],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetCategoriesQuery,
  useGetCategoryQuery,
  useAddNewCategoryMutation,
  useEditCategoryMutation,
  useDeleteCategoryMutation,
} = categoriesSlice