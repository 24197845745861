import React from 'react'
import Modal from 'common/components/Modal'
import Form from './components/Form'
import style from './index.module.sass'

const ModalTransaction = ({ modalIsOpen, toggleModal, transaction, transactionType }) => (
  <Modal
    onRequestClose={() => toggleModal(false)}
    isOpen={modalIsOpen}
    className={style.modalTransaction}
  >
    <Form transactionType={transactionType} transaction={transaction} toggleModal={toggleModal} />
  </Modal >
)

export default ModalTransaction