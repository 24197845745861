import React from 'react'
import { InView } from 'react-intersection-observer'
import classNames from 'classnames'
import { getTypeStr, toLocaleDateString } from 'common/helpers'
import { enumType as enumTypeConst } from 'common/constants'
import style from './index.module.sass'
import DeleteIcon from './ButtonsIcons/DeleteIcon'
import EditIcon from './ButtonsIcons/EditIcon'
import UserAvatar from '../UserAvatar'

const TableTransaction = ({
  hideIndexCol,
  hideCreatorCol,
  index,
  id,
  amount,
  enumType,
  enumKind,
  creator,
  category,
  date,
  title,
  toggleModalTransaction,
  toggleModalTransactionDelete,
}) => (
  <InView>
    {({ inView, ref }) => (
      <div ref={ref} className={classNames(style.flexTable, style.transaction, {
        [style.hideIndexCol] : hideIndexCol && !hideCreatorCol,
        [style.hideCreatorCol] : hideCreatorCol && !hideIndexCol,
        [style.hideIndexAndCreatorCol]: hideCreatorCol && hideIndexCol
      })} role="rowgroup">
        {(inView) ?
          (<>
            <div className={`${style.flexRow} ${style.indexCol}`} role="cell">
              <span>№:</span>
              {` ${index + 1}`}
            </div>
            <div className={
              classNames(
                style.flexRow,
                style.typeCol,
                {
                  [style.income]: enumType === enumTypeConst.INCOME,
                  [style.outcome]: enumType === enumTypeConst.OUTCOME,
                }
              )
            } role="cell">
              <span>Тип: </span>
              {getTypeStr(enumType, enumKind)}
            </div>
            <div className={`${style.flexRow} ${style.amountCol}`} role="cell">
              <span>Сумма: </span>
              {amount || '-'}
            </div>
            <div className={`${style.flexRow} ${style.categoryCol}`} role="cell">
              <span>Категория: </span>
              {(category) ? category.title : '-'}
            </div>
            <div className={`${style.flexRow} ${style.creatorCol}`} role="cell">
              <span>Создатель: </span>
              {(creator) ? <UserAvatar user={creator}/> : '-'}
            </div>
            <div className={`${style.flexRow} ${style.dateCol}`} role="cell">
              <span>Дата: </span>
              {(date) ? toLocaleDateString(date) : '-'}
            </div>
            <div className={`${style.flexRow} ${style.titleCol}`} role="cell">
              <span>Название: </span>
              {title || '-'}
            </div>
            <div className={`${style.flexRow} ${style.buttonsCol}`} role="cell">
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <button onClick={() => toggleModalTransaction({
                isOpened: true, transaction: {
                  id,
                  amount,
                  enumType,
                  enumKind,
                  category,
                  date,
                  title,
                }
              })} type="button"><EditIcon /></button>
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <button type="button" onClick={() => toggleModalTransactionDelete({
                isOpened: true, transaction: {
                  id,
                  amount,
                  enumType,
                  enumKind,
                  category,
                  date,
                  title,
                }
              })}
              >
                <DeleteIcon />
              </button>
            </div>
          </>)
          : null}
      </div>
    )}
  </InView>
)

export default TableTransaction
