import React from 'react'
import Select from 'react-select'
import { enumKindOptions } from 'common/constants'

const EnumKindMulti = ({handleChange, form, fieldId}) => (
  <div>
    <Select
      isMulti
      placeholder='Виды транзакций'
      inputId={fieldId}
      isSearchable={false}
      defaultValue={form[fieldId]}
      onChange={handleChange(fieldId)}
      options={enumKindOptions}
      styles={{
        option: (styles) => ({
          ...styles,
          backgroundColor: null,
          color: 'black !important',
          '&:hover': {
            backgroundColor: '#6FA3D3',
          },
        }),
      }}
    />
  </div>
)

export default EnumKindMulti
